import { Container, Row, Col } from 'react-bootstrap';
import { ReactComponent as SvgVzLogo } from '../Ressources/VZ-Logo.svg';

function Header() {
    return (
        <Container fluid className='header container-fluid-lg'>
            <a href='/'>
                <Row className='vz-bkg rounded ms-0 me-3 my-3 p-0 justify-content-center align-items-end'>
                    <Col className='col-auto m-3 ms-0 p-0 vz-border-white'><SvgVzLogo className='vz-img-logo' /></Col>
                    <Col className='col-auto mb-2 p-0 vz-font-white-xl'>VZDB MApp</Col>
                </Row>
            </a >
            <Row className='mx-0 my-3 vz-border-top'></Row>
        </Container>
    );
}

export default Header;