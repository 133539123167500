import PngNinja from '../Ressources/Ninja.png';
import PngFutsch from '../Ressources/Futsch.png';
import PngMuda from '../Ressources/Muda.png';
import Header from "../Components/Header.js";
import Footer from "../Components/Footer.js";

import { Container, Row, Col } from 'react-bootstrap';

function Home() {
    return (
        <div className="App">
            <Header />
            <Container className='main container-fluid-sm'>
                <a href="/muda">
                    <Row className="row vz-bkg rounded vz-border mx-0 my-3 p-0 justify-content-center align-items-center">
                        <Col className="col-6 m-3 p-0 vz-font-white-lg text-center">Zero Muda<br />Programm</Col>
                        <Col className="col-auto m-3 p-3 text-center rounded-circle vz-bkg-white vz-border"><img src={PngMuda} alt="Muda" className="vz-img-white-logo" /></Col>
                    </Row>
                </a>

                <a href="https://vzma-ninja.web.app//">
                    <Row className="row vz-bkg rounded vz-border mx-0 my-3 p-0 justify-content-center align-items-center">
                        <Col className="col-6 m-3 p-0 vz-font-white-lg text-center">VZ Gratitude Programm</Col>
                        <Col className="col-auto m-3 p-3 text-center rounded-circle vz-bkg-white vz-border"><img src={PngNinja} alt="Ninja" className="vz-img-white-logo" /></Col>
                    </Row>
                </a>
                <a href="/futsch">
                    <Row className="row vz-bkg rounded vz-border mx-0 my-3 p-0 justify-content-center align-items-center">
                        <Col className="col-6 m-3 p-0 vz-font-white-lg text-center">es isch futsch!!!</Col>
                        <Col className="col-auto m-3 p-3 text-center rounded-circle vz-bkg-white vz-border"><img src={PngFutsch} alt="Futsch" className="vz-img-white-logo" /></Col>
                    </Row>
                </a>
                <h5><center>Stay tuned,<br />more to come!</center></h5>
            </Container >
            <Footer />
        </div >
    );
};

export default Home;