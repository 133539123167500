import { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { Container, Row, Col, Button, Form, FloatingLabel } from 'react-bootstrap';
import Header from "../Components/Header.js";
import Footer from "../Components/Footer.js";
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';

import PngMuda from '../Ressources/Muda.png';

function Muda() {
    const [submissionState, setSubmissionState] = useState({
        response: null,
        error: null
    });
    const [submissionDisabled, setSubmissionDisabled] = useState(false);
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
        setSubmissionDisabled(true);
        console.log('aa:', form.current);
        emailjs.sendForm('vzma-app@gmail.com', 'NeuerMudaErfasst', form.current, 'ECIPqVegYDsAOsFoZ')

            .then(
                (result) => {
                    setSubmissionState({ response: result, error: null });
                    setTimeout(() => { setSubmissionState({ response: null }); setSubmissionDisabled(false); }, 3000);
                },
                (error) => {
                    setSubmissionState({ response: null, error: error });
                    setTimeout(() => { setSubmissionState({ error: null }); setSubmissionDisabled(false); }, 3000);
                }
            );
    };

    const [mudaHelpValue, setMudaHelpValue] = useState("help");
    const [mudaProgressValue, setMudaProgressValue] = useState("inProgress");

    const handleMudaProgressChange = (event, newProgress) => {
        setMudaProgressValue(newProgress.target.value);
    };

    const handleMudaHelpChange = (event, newHelp) => {
        setMudaHelpValue(newHelp.target.value);
    };

    return (
        <div className="App">
            <Header />
            <Container className='main container-fluid-sm'>
                <Row className="rounded vz-border mx-0 my-3 p-0 justify-content-center align-items-center">
                    <Col className="col-6 m-3 p-0 vz-font-lg text-center">Zero Muda<br />Programm</Col>
                    <Col className="col-auto m-3 p-3 text-center rounded-circle bg-light vz-border"><img src={PngMuda} alt="Muda" className="vz-img-logo" /></Col>
                </Row>
                <Row className="mx-0 my-3 p-0 justify-content-center align-items-center">
                    <Form className="form-floating m-0 p-0" ref={form} onSubmit={sendEmail}>

                        <FloatingLabel className="mb-3 vz-font" controlId="Muda" label="Muda">
                            <Form.Control required as="input" name="muda" placeholder="Muda" />
                        </FloatingLabel>

                        <FloatingLabel className="mb-3 vz-font" controlId="Beschreibung" label="Beschreibung des Muda">
                            <Form.Control required as="textarea" name="beschreibung" placeholder="Beschreibung des Muda" style={{ height: '100px' }} />
                        </FloatingLabel>

                        <FloatingLabel className="mb-3" controlId="Lösung" label="Mögliche Lösung">
                            <Form.Control as="textarea" name="losung" placeholder="Mögliche Lösung" style={{ height: '100px' }} />
                        </FloatingLabel>

                        <div className="vz-font-sm mb-3 text-center">Gewonnene Zeit für Wertschöpfung am Kunden<br /> (Stunden pro Woche, Schätzung, max. 2 Nachkommastellen)</div>
                        <FloatingLabel className="mb-3" controlId="GewonneneZeit" label="Gewonnene Zeit für Wertschöpfung">
                            <Form.Control as="input" type="number" step="0.01" name="gewonnenezeit" placeholder="Gewonnene Zeit für Wertschöpfung" />
                        </FloatingLabel>

                        <div className="vz-font-sm mb-3 text-center">Kann ich das Muda selbständig lösen?</div>
                        <ToggleButtonGroup className="mb-3 w-100 border" name="mudaToggle" onChange={handleMudaHelpChange} value={mudaHelpValue}>
                            <ToggleButton className="col-6 btn-sm" id="muda-help" type="radio" variant={"light"} value="help">Hilfe anfordern.</ToggleButton>
                            <ToggleButton className="col-6 btn-sm" id="muda-nohelp" type="radio" variant={"light"} value="noHelp">Selbständig lösen.</ToggleButton>
                        </ToggleButtonGroup>

                        {mudaHelpValue === "noHelp" ? <div className="vz-font-sm mb-3 text-center">Ich löse das Muda selbständig. Es ist im Status:</div> : null}

                        {mudaHelpValue === "noHelp" ? (
                            <ToggleButtonGroup className="mb-3 w-100 border" name="mudaProgress" onChange={handleMudaProgressChange} value={mudaProgressValue}>
                                <ToggleButton className="col-6 btn-sm" id="mudaInProgress" type="radio" variant={"light"} value="inProgress">In Arbeit.</ToggleButton>
                                <ToggleButton className="col-6 btn-sm" id="mudaDone" type="radio" variant={"light"} value="done">Umgesetzt.</ToggleButton>
                            </ToggleButtonGroup>
                        ) : null}

                        <FloatingLabel className="mb-3" controlId="Kürzel" label="Dein Kürzel">
                            <Form.Control as="input" name="kurzel" placeholder="Dein Kürzel" />
                        </FloatingLabel>

                        <div className="alert-muda">
                            {submissionState.response ?
                                <div className="alert alert-success">
                                    Das hat geklappt. Danke für deinen Input!
                                </div>
                                : submissionState.error ?
                                    <div className="alert alert-danger">
                                        Oups, da ist was schief gegangen! Versuche es später nochmals.
                                    </div>
                                    : null
                            }
                        </div>

                        <div className="vz-font-sm mb-3 text-center">Keine vertraulichen Daten!</div>

                        <Button className="w-100 btn-lg mb-3" id="Send" type="submit" variant="primary" disabled={submissionDisabled}>Senden</Button>
                    </Form>
                </Row>
            </Container>
            <Footer />
        </div>
    );
};

export default Muda;