import PngNinja from '../Ressources/Ninja.png';
import Header from "../Components/Header-lg.js";

function Ninja() {
    return (
        <div className="App">
            <Header />
            <main className='main container-fluid-lg'>
                <div className="row rounded vz-border ms-0 m-3 p-0 justify-content-center align-items-center">
                    <div className="col-6 m-3 p-0 vz-font text-center">Ninja Programm bli bla blooo bli bla blooo bli bla blooo bli bla blooo bli bla blooo bli bla blooo bli bla blooo bli bla blooo bli bla blooo bli bla blooo bli bla blooo bli bla blooo bli bla blooo bli bla blooo bli bla blooo bli bla blooo bli bla blooo bli bla blooo bli bla blooo bli bla blooo bli bla blooo bli bla blooo bli bla blooo bli bla blooo bli bla blooo bli bla blooo bli bla blooo bli bla blooo bli bla blooo bli bla blooo bli bla blooo bli bla blooo bli bla blooo bli bla blooo bli bla blooo bli bla blooo bli bla blooo bli bla blooo bli bla blooo</div>
                    <div className="col-auto m-3 p-3 text-center rounded-circle bg-light vz-border"><img src={PngNinja} alt="Ninja" className="vz-img-logo" /></div>
                </div>

            </main>
        </div>
    );
}

export default Ninja;