import { React, useRef } from 'react';
import { useLocation } from "react-router-dom"
import emailjs from '@emailjs/browser';
import { Container, Row, Col, Button, Form, FloatingLabel } from 'react-bootstrap';
import Header from "../Components/Header.js";
import Footer from "../Components/Footer.js";

import PngFutsch from '../Ressources/Futsch.png';

export const Futsch = () => {
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('vzma-app@gmail.com', 'NeuerFutschErfasst', form.current, 'ECIPqVegYDsAOsFoZ')
            .then((result) => {
                let a = document.createElement("div");
                a.classList.add("alert")
                a.classList.add("alert-success")
                a.innerText = "Das hat geklappt. Danke für deinen Input!"
                document.querySelector(".alert-futsch").appendChild(a);
            }, (error) => {
                let a = document.createElement("div");
                a.classList.add("alert")
                a.classList.add("alert-danger")
                a.innerText = "Oups, da ist was schief gegangen! Versuche es später nochmals."
                document.querySelector(".alert-futsch").appendChild(a);
            });
    };

    return (
        <div className="App">
            <Header />
            <Container className='main container-fluid-sm'>
                <Row className="row rounded vz-border mx-0 my-3 p-0 justify-content-center align-items-center">
                    <Col className="col-6 m-3 p-0 vz-font-lg text-center">es isch futsch!!!</Col>
                    <Col className="col-auto m-3 p-3 text-center rounded-circle bg-light vz-border"><img src={PngFutsch} alt="Futsch" className="vz-img-logo" /></Col>
                </Row>
                <Row className="row mx-0 my-3 p-0 align-items-center">
                    <Form className="form-floating m-0 p-0" ref={form} onSubmit={sendEmail}>
                        <div className="vz-font-sm mb-3">Was ist kaputt? Wo steht das Gerät? Fehlt was? Wo brennt's? Wie können wir dir helfen?</div>
                        <FloatingLabel className="mb-3 vz-font" controlId="Beschreibung" label="Beschreibung Problems">
                            <Form.Control as="textarea" defaultValue={params.get("problem")} name="beschreibung" placeholder="Beschreibung Problems" style={{ height: '100px' }} />
                        </FloatingLabel>
                        <div className="vz-font-sm mb-3">Gib dein Kürzel an für allfällige Rückfragen.</div>
                        <FloatingLabel className="mb-3" controlId="Kürzel" label="Dein Kürzel">
                            <Form.Control as="input" name="kurzel" placeholder="Dein Kürzel" />
                        </FloatingLabel>
                        <div className='alert-futsch'></div>
                        <div className="vz-font-sm mb-3 text-center">Keine vertraulichen Daten!</div>
                        <Button className="w-100 btn-lg mb-3" id="Send" type="submit" variant="primary">Senden</Button>
                        <div className="vz-font-sm p-1 vz-border rounded"><center>QR Code selber generieren?</center>1. gehe auf qrcode-monkey.com<br />2. URL Adresse "http://vzma.ch/futsch?problem=hier+ Gerätekennung+ oder+ vorgefertigter+ Problemtext" (Abstände mit + ausschreiben)<br />3. QR Code ausdrucken und aufhängen<br />4. QR Code führt direkt zu dieser Seite mit vorgefertigtem Problemtext</div>
                    </Form>
                </Row>
            </Container>
            <Footer />
        </div>
    );
};

export default Futsch;
