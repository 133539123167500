import { Container, Row, Col } from 'react-bootstrap';

function Footer() {
    return (
        <Container className='footer container-fluid-sm'>
            <Row className='vz-border-top m-0 p-0 justify-content-center align-items-end'>
                <Col className='m-0 p-1 text-end'>
                    <a className='vz-font-sm' href='mailto:kbui@vzch.com?subject=Feedback / Idee zur VZMA App'>
                        Feedback oder Idee? Klick hier.
                    </a>
                </Col>
            </Row>
        </Container >
    );
}

export default Footer;